(function () {
    document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
            // document ready

            window.dataLayer = window.dataLayer || [];
            const analyticsStorage = window.localStorage;
            const analyticsParams = JSON.parse(analyticsStorage.analytics);

            switch (analyticsParams.sitemapName) {
                case '/thank-you-newsletter':
                    window.dataLayer.push({
                        'event': 'submit-form-newsletter-ok'
                    });
                    break;
            }

            const promotionsActive = []
            const $targetPromoView = document.querySelectorAll('.cmp_heroBanner .slick-slider .slick-active')
            $targetPromoView.forEach(function (item) {
                let title = item.querySelector('.title').innerHTML;
                let id = title?.replace(/\s+/g, '-').toLowerCase();
                let slide = item.closest('.slick-slide') ? item.closest('.slick-slide') : false ;
                let slideList = item.parentElement.querySelectorAll('.slick-slide') ;
                let creative = slideList.length > 1 ? 'slideshow' : 'banner';
                let position = slideList.length > 1  ? slide.dataset.slickIndex : 0;
                promotionsActive.push({
                    'id': id || '',
                    'name': title || '',
                    'creative': creative || '',
                    'position': position || '',
                })
            })
            if(promotionsActive.length > 0 ) {
                window.dataLayer.push({
                    'event': 'promotionView',
                    'promotions': promotionsActive
                });
            }

            const targetPromoView = document.querySelectorAll('.cmp_heroBanner .slick-slider .slick-arrow')
            targetPromoView.forEach(link => {
                link.addEventListener("click", function (e) {
                    let promoActive = e.currentTarget.closest('.cmp_heroBanner .slick-slider').querySelector('.slick-active');
                    let title = promoActive.querySelector('.title').innerHTML;
                    let id = title?.replace(/\s+/g, '-').toLowerCase();
                    let slide = promoActive.closest('.slick-slide') ? promoActive.closest('.slick-slide') : false;
                    let creative = slide ? 'slideshow' : 'banner';
                    let position = slide ? slide.dataset.slickIndex : 0;
                    window.dataLayer.push({
                        'event': 'promotionView',
                        'ecommerce': {
                            'promotions': {
                                'id': id || '',
                                'name': title || '',
                                'creative': creative || '',
                                'position': position || '',
                            }
                        }
                    });
                });
            })

            const targetPromotion = document.querySelectorAll('.cmp_heroBanner a.action-link')
            targetPromotion.forEach(link => {
                link.addEventListener("click", function (e) {
                    let title = e.currentTarget.parentNode.querySelector('.title').innerHTML;
                    let id = title?.replace(/\s+/g, '-').toLowerCase();
                    let slide = e.currentTarget.closest('.slick-slide');
                    let creative = slide ? 'slideshow' : 'banner';
                    let position = slide ? slide.dataset.slickIndex : 0;
                    window.dataLayer.push({
                        'event': 'promotionClick',
                        'ecommerce': {
                            'promoClick': {
                                'promotions': [{
                                    'id': id || '',
                                    'name': title || '',
                                    'creative': creative || '',
                                    'position': position || '',
                                }]
                            }
                        }
                    });
                });
            });
        }
    };
})();